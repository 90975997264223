import { useToast } from '@chakra-ui/react';
import ReactDOM from 'react-dom';
import React, { useCallback, useEffect, useRef } from 'react';
import i18n from 'lib/i18n';
import mapboxgl from 'mapbox-gl';
import { handleToastMessage } from 'utils/toast';
import { TOAST_STATUS } from 'utils/constants';
import 'mapbox-gl/dist/mapbox-gl.css';
import Popup from 'components/MapFeatures/Popup';
import { useRouter } from 'next/router';
import { monitorURL } from 'utils/routingUtils';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'stores/currentUser';
import { isGallonsOrLiters } from 'utils/measureUnits';

const { useTranslation } = i18n;

const useMonitorListMap = (monitors, isInMapMode) => {
  const mapContainer = useRef(null);
  const router = useRouter();
  const toast = useToast();
  const map = useRef(null);
  const { t } = useTranslation();
  const mapboxStyle = 'mapbox://styles/mapbox/streets-v11';
  const currentUser = useSelector(getCurrentUser);
  const { measureUnit } = currentUser;
  const unit = isGallonsOrLiters(measureUnit);

  const addMarkersToMap = useCallback(
    mapCanvas => {
      monitors.forEach(monitor => {
        const popupNode = document.createElement('div');
        ReactDOM.render(
          <Popup
            unit={unit}
            monitor={monitor}
            goToDetail={() => router.push(monitorURL(monitor.id))}
            close={() => popup.remove()}
          />,
          popupNode,
        );

        const popup = new mapboxgl.Popup({
          closeButton: false,
          maxWidth: 450,
        }).setDOMContent(popupNode);

        new mapboxgl.Marker()
          .setLngLat([monitor.longitude, monitor.latitude])
          .setPopup(popup)
          .addTo(mapCanvas);
      });
    },
    [monitors, unit, router],
  );

  const fitMapToBounds = useCallback(
    mapCanvas => {
      const bounds = new mapboxgl.LngLatBounds();
      monitors.forEach(({ longitude, latitude }) => {
        if (monitors.length > 1) {
          bounds.extend([longitude, latitude]);
        }
      });
      if (monitors.length > 1) {
        mapCanvas.fitBounds(bounds, { padding: 30 });
      }
    },
    [monitors],
  );

  useEffect(() => {
    if (monitors.length === 0 || !isInMapMode) return;
    try {
      const mapCanvas = new mapboxgl.Map({
        container: mapContainer.current || document.createElement('div'),
        style: mapboxStyle,
        center: [monitors[0].longitude, monitors[0].latitude],
        zoom: 5,
        renderWorldCopies: false,
      });
      map.current = mapCanvas;

      addMarkersToMap(mapCanvas);
      fitMapToBounds(mapCanvas);

      mapCanvas.addControl(new mapboxgl.NavigationControl());
    } catch (e) {
      handleToastMessage(toast, e.message, TOAST_STATUS.ERROR);
    }
  }, [addMarkersToMap, fitMapToBounds, t, monitors, toast, isInMapMode]);

  return mapContainer;
};

export default useMonitorListMap;
