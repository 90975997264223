import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import PropTypes, { string } from 'prop-types';
import { IoClose, IoContrastOutline, IoTimeSharp } from 'react-icons/io5';
import { tankType } from 'types';
import { formatDate } from 'utils/dateUtils';
import { DATE_TYPE } from 'utils/constants';
import themes from 'providers/ThemeProvider/theme';
import TankIcon from 'components/icons/TankIcon';
import moment from 'moment';

const Popup = ({ monitor, goToDetail, close, unit }) => {
  const { lastReadingAt, serial, description, zoneName } = monitor;
  const { inventoryRatio, inventory } = monitor?.currentLevel || {};
  const theme = themes.default;

  return (
    <Box p='14px' borderRadius='5px' shadow='lg'>
      <Flex alignItems='center' justifyContent='space-between'>
        <Text
          color={theme.colors.alpha[500]}
          fontSize={theme.fontSizes.xl}
          fontWeight='700'
        >
          Monitor Description
        </Text>
        <IoClose size='15px' onClick={close} cursor='pointer' />
      </Flex>

      <Flex mt='24px'>
        <Flex direction='column' w='25%'>
          <Text
            color={theme.colors.gray[700]}
            fontWeight={700}
            maxW='150px'
            fontSize={theme.fontSizes.sm}
            overflow='hidden'
            textOverflow='ellipsis'
            noOfLines='2'
          >
            {description || `${serial}`}
          </Text>
          <Text
            mt='1px'
            color={theme.colors.gray[700]}
            fontSize={theme.fontSizes.xs}
          >
            {serial}
          </Text>
          <Text
            mt='1px'
            color={theme.colors.gray[400]}
            fontSize={theme.fontSizes.xs}
          >
            {`Zone: ${zoneName}`}
          </Text>
        </Flex>
        <Divider
          mx='26px'
          height='auto'
          orientation='vertical'
          border='inherit'
        />
        <Flex direction='column'>
          <Flex>
            <IoContrastOutline
              transform='rotate(270deg)'
              size='18px'
              color={theme.colors.gray[700]}
            />
            <Text
              ml='11px'
              color={theme.colors.gray[700]}
              fontWeight={700}
              fontSize={theme.fontSizes.xs}
            >
              INVENTORY
            </Text>
          </Flex>
          <Flex mt='11px'>
            <Box w='25px' mr='14px'>
              <TankIcon
                inventoryRatio={Number(inventoryRatio)}
                color={theme.colors.alpha[500]}
                boxSize='4em'
              />
            </Box>
            <Flex direction='column'>
              {inventory && inventoryRatio ? (
                <>
                  <Text
                    color={theme.colors.gray[700]}
                    fontSize={theme.fontSizes.xs}
                    fontWeight={700}
                  >
                    {`${Number(inventoryRatio).toFixed(1)}%`}
                  </Text>
                  <Text
                    mt='2px'
                    color={theme.colors.gray[700]}
                    fontSize={theme.fontSizes.xs}
                  >
                    {`${Number(inventory).toFixed(1)} ${unit}`}
                  </Text>
                </>
              ) : (
                <Text
                  alignSelf='center'
                  my='auto'
                  color={theme.colors.gray[700]}
                  fontSize={theme.fontSizes.xs}
                >
                  N/A
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Divider
          mx='26px'
          height='auto'
          orientation='vertical'
          border='inherit'
        />
        <Flex direction='column'>
          <Flex>
            <IoTimeSharp size='18px' color={theme.colors.gray[700]} />
            <Text
              ml='11px'
              mr='16px'
              color={theme.colors.gray[700]}
              fontWeight={700}
              fontSize={theme.fontSizes.xs}
            >
              LAST READING
            </Text>
          </Flex>

          <Flex direction='column' mt='11px'>
            <Text fontSize={theme.fontSizes.xs}>{`${formatDate(
              lastReadingAt,
              DATE_TYPE.MEDIUM,
              'en',
            )}`}</Text>
            <Text fontSize={theme.fontSizes.xs} color={theme.colors.gray[400]}>
              {moment(lastReadingAt).fromNow()}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems='center' mt='25px'>
        <Button
          borderRadius='100px'
          padding='8px 24px'
          backgroundColor={theme.colors.alpha[500]}
          color='white'
          ml='auto'
          fontWeight={700}
          fontSize={theme.fontSizes.md}
          onClick={goToDetail}
        >
          Go to Monitor
        </Button>
      </Flex>
    </Box>
  );
};

Popup.propTypes = {
  monitor: tankType,
  goToDetail: PropTypes.func,
  close: PropTypes.func,
  unit: string,
};

export default Popup;
