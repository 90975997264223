import React from 'react';
import { Badge, Flex, Spinner, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import capitalize from 'lodash.capitalize';
import { tType } from 'types';
import { RESOURCE_STATUS } from 'utils/constants';

const { withTranslation } = i18n;

const alarms = [
  {
    name: 'High',
    attribute: 'alarmCount',
    subAttribute: 'high',
    bg: 'beta.400',
    alarmFilterField: 'high',
  },
  {
    name: 'Low',
    attribute: 'alarmCount',
    subAttribute: 'low',
    bg: 'zeta.400',
    alarmFilterField: 'low',
  },
  {
    name: 'Low Battery',
    attribute: 'alarmCount',
    subAttribute: 'lowBattery',
    bg: 'epsilon.400',
    alarmFilterField: 'low_battery',
  },
  // Commenting out until we implement late report alarms
  // {
  //   name: 'Late Report',
  //   attribute: 'alarmCount',
  //   subAttribute: 'lateReports',
  //   bg: 'gray.400',
  //   alarmFilterField: 'late_reports',
  // },
];

const SearchFilters = ({ alarmFilter, submitAlarmFilter, alarmsCount, t }) => {
  const handleAlarmSubmit = filter => {
    if (filter === alarmFilter) {
      submitAlarmFilter('');
    } else {
      submitAlarmFilter(filter);
    }
  };

  const handleAlarmsCount = alarm => {
    const alarmsTotal = Object.entries(alarmsCount?.value).map(
      ([key, value]) => ({
        name: key,
        value,
      }),
    );

    const count = alarmsTotal.find(a => a.name === alarm.subAttribute);

    return count ? count.value : 0;
  };

  return (
    <Flex flexWrap='wrap' alignItems='center'>
      <Flex flexDirection='row'>
        <Text variant='headingMd' marginRight={4}>
          {capitalize(t('search_filter.filter_by_alarm'))}
        </Text>
        <Flex flexDirection='row' alignItems='center' flexWrap='wrap'>
          {alarms.map((alarm, index) => (
            <Flex key={alarm.name} alignItems='center'>
              <Text
                variant='textSm'
                mr={alarms.length === index + 1 ? '2' : ''}
                ml='2'
              >
                {alarm.name}
              </Text>
              <Badge
                color={
                  alarmFilter === alarm.alarmFilterField ? 'white' : alarm.bg
                }
                backgroundColor={
                  alarmFilter === alarm.alarmFilterField
                    ? alarm.bg
                    : 'transparent'
                }
                border='1px solid'
                borderColor={alarm.bg}
                alignItems='center'
                justifyContent='center'
                display='flex'
                cursor='pointer'
                mx={alarms.length === index + 1 ? '0' : '2'}
                minW='2.5rem'
                onClick={() => handleAlarmSubmit(alarm.alarmFilterField)}
              >
                {alarmsCount.resourceStatus !== RESOURCE_STATUS.READY ? (
                  <Spinner display='block' marginX='auto' size='sm' />
                ) : (
                  alarmsCount?.value && handleAlarmsCount(alarm)
                )}
              </Badge>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

SearchFilters.propTypes = {
  alarmFilter: PropTypes.string,
  submitAlarmFilter: PropTypes.func,
  alarmsCount: PropTypes.shape(),
  t: tType,
};

export default withTranslation()(SearchFilters);
