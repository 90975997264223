import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMapMonitorsThunk, getMapMonitors } from 'stores/monitors';

const useMapMonitors = ({
  isInMapMode,
  search,
  currentCompanyId,
  zones,
  zoneId,
  alarmFilter,
  fillLevelLower,
  fillLevelUpper,
  includeInactive,
}) => {
  const dispatch = useDispatch();
  const mapMonitors = useSelector(getMapMonitors);
  useEffect(() => {
    if (isInMapMode) {
      dispatch(
        fetchMapMonitorsThunk({
          search,
          currentCompanyId,
          zoneIds:
            zoneId === undefined ? zones?.map(zone => zone.id) : [zoneId],
          alarmFilter,
          fillLevelLower,
          fillLevelUpper,
          includeInactive,
        }),
      );
    }
  }, [
    alarmFilter,
    currentCompanyId,
    dispatch,
    fillLevelLower,
    fillLevelUpper,
    isInMapMode,
    search,
    zones,
    zoneId,
    includeInactive,
  ]);

  return mapMonitors;
};

export default useMapMonitors;
